<template>
  <fieldset>
    <DocFields
      :modelValue="data"
      :document-type-id="documentTypeId"
      :document-reg-num="documentRegNum"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #additional-fields-bottom>
        <MultiSelectField
          :modelValue="modelValue.artZuRef"
          @update:modelValue="updateVector($event, v$.artZuRef)"
          :errors="v$.artZuRef.$errors"
          :itemValidationRule="itemValidationRule"
          :options="vectors"
          @reload="fetchWithDelay"
          data-qa="handmadeLand"
          keyProp="externalId"
        >
          <label class="label label--required"
            >Искусственный земельный участок</label
          >
        </MultiSelectField>
      </template>
    </DocFields>
  </fieldset>
</template>

<script>
import DocSection9 from "../../../models/documents/docSection9";
import DocFields from "./DocFields";
import MultiSelectField from "../../basic/MultiSelectField.vue";
import { toRefs } from "vue";
import { helpers, required } from "@vuelidate/validators";
import useValidator from "./validator";
import { useVectorArtLandPlot } from "../../../hooks/vector";
export default {
  emits: ["update:modelValue", "update:vector"],
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => new DocSection9(),
    },
    documentTypeId: {
      type: Number,
      required: true,
    },
    documentRegNum:{
      type: String,
      required: false
    }
  },
  components: { DocFields, MultiSelectField },
  setup(props, { emit }) {
    const { modelValue: data } = toRefs(props);
    const { vectors, fetchWithDelay } = useVectorArtLandPlot();

    function update(fieldName, value, field) {
      const newValue = { ...props.modelValue };
      newValue[fieldName] = value;
      if (field) field.$model = value;
      emit("update:modelValue", newValue);
    }

    let rules = {
      artZuRef: {
        required: helpers.withMessage("Заполните поле", required),
      },
    };

    let itemValidationRule = {
      externalId: helpers.withMessage("Заполните или удалите неиспользуемое поле", (value) => {
        return value !== null && value["externalId"] !== undefined
      })
    }

    const v$ = useValidator(data, rules);

    function updateVector(value, field) {
      update("artZuRef", value, field);
      emit("update:vector", value);
    }

    return { data, update, updateVector, vectors, fetchWithDelay, v$, itemValidationRule };
  },
};
</script>